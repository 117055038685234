import * as amplitude from '@amplitude/analytics-browser'

export const Analytics = {
  init: () => {
    amplitude.init('b767658f050c5f5f0b676f6a2cadaf15', {
      autocapture: true,
    })
  },
  conversionEventError: () => {
    amplitude.logEvent('gtag conversion event error')
  },
  clickDownloadButton: () => {
    amplitude.track('Download button click')
  },
  clickRequestDemo: () => {
    amplitude.track('Request Demo click')
  },
  clickToInstagramLink: () => {
    amplitude.track('Open Instagram link')
  },
  clickToLinkedinLink: () => {
    amplitude.track('Open Linkedin link')
  },
  scrollTo: (sectionName) => {
    amplitude.track(`Scroll to ${sectionName}`)
  },
}
