import React from 'react'
import { Box, Stack, Link } from '@mui/material'

import { Analytics } from 'analytics'

import logo from 'assets/logo.svg'
import App_Store_Button from 'assets/images/app_store_button.svg'

const URL = 'https://apps.apple.com/us/app/mealjoy-food-recommendation/id6504121432'

export const Header = () => {
  const handleDownloadClick = () => {
    if (typeof window.gtag === 'function') {
      Analytics.clickDownloadButton()

      window.gtag('event', 'conversion', {
        send_to: 'AW-11276953309/2IhhCPuYm6gaEN3NooEq',
        event_callback: () => window.open(URL, '_blank', 'noopener,noreferrer'),
      })
    } else {
      Analytics.conversionEventError()
      window.open(URL, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <Box
      component="header"
      position="sticky"
      zIndex={(theme) => theme.zIndex.appBar}
      top={0}
      sx={{ backgroundColor: '#ffffff', p: '16px 0', boxShadow: '0 2px 20px #5780be1a' }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        maxWidth={1240}
        margin="0 auto"
      >
        <Link href="/" display="inline-flex" flex={1} ml={2} maxWidth={{ xs: 125, md: 180 }}>
          <img src={logo} alt="" style={{ width: '100%' }} />
        </Link>
        <Link sx={{ mr: 2 }} component="button" onClick={handleDownloadClick}>
          <img src={App_Store_Button} alt="" />
        </Link>
      </Stack>
    </Box>
  )
}
